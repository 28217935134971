<template>
  <div>
    <el-form
      v-loading="loading"
      :model="ruleForm"
      status-icon
      :rules="rules"
      ref="ruleForm"
      label-width="100px"
      label-position="left"
      class="demo-ruleForm"
    >
      <div class="flex">
        <div class="text">预测</div>
        <el-form-item label="" prop="value">
          <el-switch
            v-model="ruleForm.value"
            active-color="#13ce66"
            inactive-color="#ff4949"
            inactive-text="开启"
            style="margin-left: 20px"
          >
          </el-switch>
        </el-form-item>
        <div class="text2">预算结算规则</div>
        <el-form-item label="" prop="win" id="win">
          <span>胜+</span>
          <el-input
            placeholder="请输入内容"
            v-model="ruleForm.win"
            autocomplete="off"
            :style="{ width: '200px' }"
          ></el-input>
        </el-form-item>
        <el-form-item label="" prop="defeat" id="defeat">
          <span>败-</span>
          <el-input
            placeholder="请输入内容"
            v-model="ruleForm.defeat"
            autocomplete="off"
            :style="{ width: '200px' }"
          ></el-input>
        </el-form-item>
        <!-- 周按钮 -->
        <el-form-item>
          <el-button type="primary" @click="showDialog = true">
            新增周</el-button
          >
        </el-form-item>
      </div>

      <div v-if="ruleForm.value === true">
        <weekItem
          v-for="item in moni"
          :key="item.index"
          :id="item.id"
          :start-time="item.startTime"
          :end-time="item.endTime"
          ref="childRef"
          @delete="handleDelete"
        ></weekItem>
      </div>
      <!-- 预测规则 -->
      <el-form-item
        label="预测规则"
        prop="quillEditorQiniuyuce"
        id="quillEditorQiniuyuce"
        class="quill-editor"
      >
        <el-upload
          class="avatar-uploader"
          :accept="'image/*'"
          :show-file-list="false"
          action="#"
          style="height: 0"
          :http-request="httpUploadEditorRequestyuce"
        >
        </el-upload>

        <quill-editor
          class="editor ql-editor"
          v-model="ruleForm.rule"
          ref="customQuillEditoryuce"
          :options="editorOptionyuce"
        >
        </quill-editor>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submitForm('ruleForm')"
          >提交</el-button
        >
      </el-form-item>
    </el-form>
    <!-- 新增周 -->
    <el-dialog :visible.sync="showDialog" title="新增周" width="30%" center>
      <el-form
        ref="addweek"
        :model="addweek"
        class="addweek"
        :label-position="right"
        :rules="rules_dialog"
      >
        <el-form-item label="名称" prop="id" class="addname">
          <el-select v-model="addweek.id" :style="{ width: '220px' }">
            <!-- 下拉框选项 -->
            <el-option
              v-for="week in 9"
              :key="week"
              :label="'第' + week + '周'"
              :value="week.toString()"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="开始时间" prop="startTime">
          <el-date-picker
            v-model="addweek.startTime"
            type="datetime"
            placeholder="选择开始时间"
            format="yyyy-MM-dd HH:mm:ss"
            value-format="yyyy-MM-dd HH:mm:ss"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="结束时间" prop="endTime">
          <el-date-picker
            v-model="addweek.endTime"
            type="datetime"
            placeholder="选择结束时间"
            format="yyyy-MM-dd HH:mm:ss"
            value-format="yyyy-MM-dd HH:mm:ss"
          >
          </el-date-picker>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="cancellation">取消</el-button>
        <el-button type="primary" @click="saveWeek">保存</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import weekItem from "./week.vue";
import { randomStr } from "@/utils/randomStr";
import { uploadOssUrl } from "@/utils/uploadUrl";
import { toolbarOptions } from "../../public.js/toolbarOptions";
export default {
  name: "prediction",
  components: {
    weekItem,
  },
  props: {
    season_id: {
      type: String,
    },
    match_id: {
      type: String,
    },
  },
  data() {
    return {
      loading: false,
      right: "right",
      moni: [],
      stage_id: "",
      ruleForm: {
        win: "",
        defeat: "",
        value: true,
        is_open: 0,
        rule: null,
        rule_url: null,
        _id: "",
      },
      type: "match_s4",
      addweek: {
        id: "",
        startTime: "",
        endTime: "",
      },
      showDialog: false,
      rules: {
        win: [{ required: true, message: "请输入内容", trigger: "blur" }],
        defeat: [{ required: true, message: "请输入内容", trigger: "blur" }],
        nameRules: [{ required: true, message: "请输入名称", trigger: "blur" }],
      },
      rules_dialog: {
        id: [{ required: true, message: "请选择名称", trigger: "change" }],
        startTime: [
          { required: true, message: "请选择开始时间", trigger: "change" },
        ],
        endTime: [
          { required: true, message: "请选择结束时间", trigger: "change" },
          { validator: this.checkEndTime, trigger: "blur" },
        ],
      },
      // 预测规则
      editorOptionyuce: {
        placeholder: "请填写预测规则",
        modules: {
          imageResize: {
            displayStyles: {
              backgroundColor: "black",
              border: "none",
              color: "white",
            },
            modules: ["Resize", "DisplaySize", "Toolbar"],
          },
          toolbar: {
            container: toolbarOptions, // 工具栏
            handlers: {
              size: function (value) {
                if (value === "custom-size") {
                  value = prompt("请输入字体大小");
                  if (
                    !/^(([2-6][0-9])|([7][0-2])|([1][8-9]))(rpx)$/.test(value)
                  ) {
                    alert(
                      "请输入合法的字体大小，使用rpx单位， 大小在18rpx-72rpx之间, 1rpx=2px， 用于适配不同设备"
                    );
                    value = "";
                  }
                }

                this.quill.format("size", value);
              },
              color: function (value) {
                if (value === "custom-color") {
                  value = prompt("请输入十六进制的颜色");
                  if (!/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/.test(value)) {
                    alert("请输入合法的十六进制颜色");
                    value = "";
                  }
                }

                this.quill.format("color", value);
              },
              image: function (value) {
                if (value) {
                  document
                    .querySelector(
                      "#quillEditorQiniuyuce .avatar-uploader input"
                    )
                    .click();
                } else {
                  this.quill.format("image", false);
                }
              },
              video: function (value) {
                if (value) {
                  document
                    .querySelector(
                      "#quillEditorQiniuyuce .avatar-uploader input"
                    )
                    .click();
                } else {
                  this.quill.format("video", false);
                }
              },
            },
          },
        },
      },
    };
  },
  mounted() {
    // this.get_stage_list();
    // this.handleQuery()
  },
  methods: {
    ...mapActions("tabs", [
      "removeTabByPath",
    ]),
    // 富文本
    async httpUploadEditorRequestyuce(item) {
      this.loading = true;
      let fileName = randomStr() + ".jpg";
      let path = uploadOssUrl().notice + fileName;

      let result = await this.$http.uploadFile(path, fileName, item.file);
      if (result) {
        console.log("预测管理上传成功");
        // this.$emit('upload',res, file)
        // console.log(res, file);
        //拼接出上传的图片在服务器的完整地址
        let imgUrl = result;
        let type = imgUrl.substring(imgUrl.lastIndexOf(".") + 1);
        console.log("1", type);
        // 获取富文本组件实例
        let quill = this.$refs.customQuillEditoryuce.quill;
        // 获取光标所在位置
        let length = quill.getSelection().index;
        // 插入图片||视频  res.info为服务器返回的图片地址
        if (type == "mp4" || type == "MP4") {
          quill.insertEmbed(length, "video", imgUrl);
        } else {
          quill.insertEmbed(length, "image", imgUrl);
        }
        // 调整光标到最后
        quill.setSelection(length + 1);
        //取消上传动画
        this.quillUpdateImg = false;
      } else {
        this.$message.error("上传失败");
      }

      this.loading = false;
    },
    // 深拷贝
    deepClone(obj) {
      if (obj === null || typeof obj !== "object") {
        return obj;
      }
      const clone = Object.assign({}, obj);
      Object.keys(clone).forEach((key) => {
        clone[key] = this.deepClone(clone[key]);
      });
      return clone;
    },
    // 取消
    cancellation() {
      this.$refs.addweek.resetFields();
      this.showDialog = false;
    },
    // 保存
    saveWeek() {
      this.$refs.addweek.validate((valid) => {
        const startTime = this.addweek.startTime;
        const endTime = this.addweek.endTime;
        if (startTime && endTime) {
          if (startTime >= endTime) {
            this.$message.error("结束时间必须大于开始时间");
            return;
          }
        }
        if (valid) {
          const newObj = this.deepClone(this.addweek);
          this.moni.push(newObj);
          this.showDialog = false;
          this.$refs.addweek.resetFields();
        } else {
          this.$message.error("请填写必填项");
        }
      });
    },
    //回显
    async handleQuery() {
      const { data } = await this.$http.match.get_info_rule({
        match_id: this.match_id,
      });
      if (this.moni.length >= data.data.length) {
        return;
      }
      this.ruleForm._id = data.data[0]._id;
      this.ruleForm.win = data.data[0].win;
      this.ruleForm.defeat = data.data[0].fail;
      this.ruleForm.is_open = data.data[0].is_open;
      this.ruleForm.rule = data.data[0].charge_rule;
      let time = data.data[0].charge_time;
      let oneweek = {
        id: 1,
        startTime:
          time.first_start === "1970-01-01T00:00:00.000Z"
            ? ""
            :  new Date(time.first_start),
        endTime:
          time.first_end === "1970-01-01T00:00:00.000Z" ? "" : new Date(time.first_end) ,
      };
      let twoweek = {
        id: 2,
        startTime:
          time.second_start === "1970-01-01T00:00:00.000Z"
            ? ""
            : new Date(time.second_start),
        endTime:
          time.second_end === "1970-01-01T00:00:00.000Z" ? "" : new Date(time.second_end),
      };
      let threeweek = {
        id: 3,
        startTime:
          time.third_start === "1970-01-01T00:00:00.000Z"
            ? ""
            : new Date(time.third_start),
        endTime:
          time.third_end === "1970-01-01T00:00:00.000Z" ? "" : new Date(time.third_end),
      };
      if (this.moni.length > 2) {
        return;
      }
      if (oneweek.startTime != "") {
        this.moni.push(oneweek);
      }
      if (twoweek.startTime != "") {
        this.moni.push(twoweek);
      }
      if (threeweek.startTime != "") {
        this.moni.push(threeweek);
      }
    },
    goBack() {
      this.$router.push({
        path: `/match/subject/list`,
        query: {
          match_id: this.match_id,
          match_type: this.matchType,
        },
      });
      this.removeTabByPath(`/match/subject/match_segment`);
    },
    // 提交
    submitForm(formName, invalidFields) {
      if (this.moni.length < 3) {
        this.$message.error(
              "请填写三周的预测时间"
            );
            return
      }
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          let foundIndexes = [];
          this.moni.forEach((item, index) => {
            if (item.startTime === "" || item.endTime === "") {
              foundIndexes.push(index);
            }
          });
          if (foundIndexes.length > 0) {
            this.$message.error(
              "请填写第" + foundIndexes.join("、") + "周的时间"
            );
            return;
          }
          //预选赛规则
          let rule_url = await this.uploadContent("rule", this.ruleForm.rule);
          if (!rule_url || !rule_url.length) {
            this.$message.error("保存失败, 上传预选赛规则失败");
            return;
          }
          if (this.ruleForm.value === true) {
            this.ruleForm.is_open = 0;
          } else {
            this.ruleForm.is_open = 1;
          }
          const defaultTime = {
            startTime: null,
            endTime: null,
          };
         const time = Object.values(this.$refs.childRef).map(refName => refName);
            const first = time[0].form || defaultTime;
            console.log("🚀 ~ this.$refs[formName].validate ~ first:", first)
          const second = time[1].form || defaultTime;
          console.log("🚀 ~ this.$refs[formName].validate ~ second:", second)
          const third = time[2].form || defaultTime;
          console.log("🚀 ~ this.$refs[formName].validate ~ third:", third)

      
          let d = {
            charge_time: {
              first_start:first.startTime ,
              first_end:first.endTime,
              second_end:second.endTime,
              second_start:second.startTime,
              third_end:third.endTime,
              third_start:third.startTime,
            },
            win: Number(this.ruleForm.win),
            fail: Number(this.ruleForm.defeat),
            is_open: this.ruleForm.is_open,
            match_id: this.match_id,
            charge_rule: this.ruleForm.rule,
            _id: this.ruleForm._id,
          };
          const res = await this.$http.match.save_info_points(this.type, d);
          if (res.code === 0) {
            this.goBack();
            this.$message.success("提交成功");
          }
        } else {
          let fileds = Object.keys(invalidFields);
          if (fileds.length) {
            let id1 = fileds[0];
            if (id1 == "desc") {
              id1 = "quillEditorQiniu";
            }

            if (document.getElementById(id1)) {
              document
                .getElementById(id1)
                .scrollIntoView({ behavior: "smooth" });
            }

            let refKey = id1.replace(/\./g, "_");
            if (this.$refs[refKey]) {
              this.$refs[refKey].focus();
            }
          }
          return false;
         }
      });
    },
    // 删除周
    handleDelete(id) {
      this.moni = this.moni.filter((item) => item.id !== id);
    },
    // 转rule_url
    async uploadContent(t, d) {
      let blob = new Blob([d], { type: "text/html;charset=utf-8" });

      // 这么写是因为文件转换是异步任务
      let transToFile = async (blob, fileName, fileType) => {
        return new window.File([blob], fileName, {
          type: fileType,
          charset: "utf-8",
        });
      };

      let fileName = t + "_" + randomStr() + ".html";
      let textContain = transToFile(blob, fileName, "application/json");

      // 转换完成后可以将file对象传给接口
      return await new Promise((resolve, reject) => {
        textContain
          .then(async (res) => {
            let file = res;
            let path = uploadOssUrl().richText + this.type + "/" + fileName;
            console.log(path);
            let result = await this.$http.uploadFile(path, fileName, file);
            resolve(result);
          })
          .catch(() => {
            resolve(null);
          });
      });
    },
  },
};
</script>

<style lang="less" scoped>
.demo-ruleForm {
  padding: 30px 80px 0;
  .flex {
    display: flex;
    flex-wrap: wrap;
    .text {
      height: 33px;
      line-height: 33px;
    }
    .text2 {
      height: 33px;
      line-height: 33px;
      margin-left: 50px;
    }
  }
  /deep/.el-form-item__content {
    margin-left: 20px !important;
  }
}
.quill-editor {
  width: 425px;
}
.addweek {
  padding: 0 6rem;
  .addname {
    margin-left: 26px;
  }
}
</style>